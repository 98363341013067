/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type {
  FreeForAllGatekeeper,
  FreeForAllGatekeeperInterface,
} from "../FreeForAllGatekeeper";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    name: "register",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "contract MACI",
        name: "_maci",
        type: "address",
      },
    ],
    name: "setMaciInstance",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const _bytecode =
  "0x608060405234801561001057600080fd5b506101ac806100206000396000f3fe608060405234801561001057600080fd5b50600436106100365760003560e01c806313e65cf31461003b57806324b8fbf614610050575b600080fd5b61004e610049366004610128565b610063565b005b61004e61005e36600461006a565b610066565b50565b5050565b6000806040838503121561007c578182fd5b823561008781610161565b915060208301356001600160401b03808211156100a2578283fd5b818501915085601f8301126100b5578283fd5b8135818111156100c7576100c761014b565b604051601f8201601f19908116603f011681019083821181831017156100ef576100ef61014b565b81604052828152886020848701011115610107578586fd5b82602086016020830137856020848301015280955050505050509250929050565b600060208284031215610139578081fd5b813561014481610161565b9392505050565b634e487b7160e01b600052604160045260246000fd5b6001600160a01b038116811461006357600080fdfea26469706673582212200ccdf07f068c4ed00b74ef17a6e262dffbb7da929b1d203004b1043f862c72cd64736f6c63430008010033";

type FreeForAllGatekeeperConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: FreeForAllGatekeeperConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class FreeForAllGatekeeper__factory extends ContractFactory {
  constructor(...args: FreeForAllGatekeeperConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<FreeForAllGatekeeper> {
    return super.deploy(overrides || {}) as Promise<FreeForAllGatekeeper>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): FreeForAllGatekeeper {
    return super.attach(address) as FreeForAllGatekeeper;
  }
  connect(signer: Signer): FreeForAllGatekeeper__factory {
    return super.connect(signer) as FreeForAllGatekeeper__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): FreeForAllGatekeeperInterface {
    return new utils.Interface(_abi) as FreeForAllGatekeeperInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): FreeForAllGatekeeper {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as FreeForAllGatekeeper;
  }
}
